import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";

function MenuLink({ toPath, name, pathname }) {
  const LinkWrapper = styled(Link)`
    text-decoration: none;
    color: inherit;
    &:hover {
      color: #d9534f !important;
      text-decoration: none;
    }
  `;

  return (
    <p
      className="text-secondary"
      style={{ margin: 0, paddingLeft: 8, paddingRight: 8 }}
      align="right"
    >
      <LinkWrapper
        to={toPath}
        style={{
          color: pathname === toPath ? `#d9534f` : `inherit`
        }}
      >
        {name}
      </LinkWrapper>
    </p>
  );
}

export default MenuLink;
