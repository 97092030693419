import React from "react";
import { Container } from "react-bootstrap";

function Footer() {
  return (
    <footer>
      <Container>
        <small>
          <span className="text-muted">
            Copyright © {new Date().getFullYear()}
          </span>
          <span className="text-dark"> Stanley Li.</span>
          <span className="text-muted"> All rights reserved.</span>
        </small>
      </Container>
    </footer>
  );
}

export default Footer;
