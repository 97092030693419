import React from "react";

function Maintenance() {
  return (
    <div className="d-flex" style={{ height: "100%", paddingTop: "16px" }}>
      <p
        className="card card-body"
        style={{ justifyContent: "center", alignItems: "center" }}
      >
        <em>We’re currently undergoing a refresh. Please stay tuned!</em>
      </p>
    </div>
  );
}

export default Maintenance;
