import React from "react";
import PropTypes from "prop-types";
import { useStaticQuery, graphql } from "gatsby";

import Header from "./header";
import Footer from "./footer";
import "./layout.css";

const Layout = ({ children, location }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
      sitePage {
        path
      }
    }
  `);

  return (
    <>
      <Header siteTitle={data.site.siteMetadata.title} location={location} />
      <div
        style={{ backgroundColor: "#F4F7FB", height: "calc(100vh - 113px)" }}
      >
        <main style={{ height: "100%" }}>{children}</main>
        <Footer />
      </div>
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired
};

export default Layout;
