import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { Navbar, Container, NavbarBrand } from "react-bootstrap";
import Img from "gatsby-image";
import PropTypes from "prop-types";

import MenuLink from "../ui.library/menu.link";

function Header({ location }) {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "profile-image.jpeg" }) {
        childImageSharp {
          # Specify the image processing specifications right in the query.
          # Makes it trivial to update as your page's design changes.
          fixed(width: 38, height: 38, quality: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `);

  return (
    <Navbar
      expand="sm"
      style={{
        backgroundColor: "white",
        borderBottom: "1px solid rgba(0,40,100,.12)"
      }}
    >
      <Container>
        <NavbarBrand href="/" className={"mr-2"}>
          <div className="d-flex">
            <Img
              fixed={data.file.childImageSharp.fixed}
              objectFit="cover"
              alt="Stanley Li"
              style={{ borderRadius: "50%" }}
            />
            <div style={{ marginLeft: "8px", marginTop: "-3px" }}>
              <p className="mb-2 h5">Stanley Li</p>
              <p
                className="mb-0 text-secondary"
                style={{ lineHeight: 0, fontSize: "small" }}
              >
                <i>Personal Blog</i>
              </p>
            </div>
          </div>
        </NavbarBrand>
        <Navbar.Toggle
          aria-controls="collapse-links"
          style={{
            borderColor: `white`,
            cursor: `pointer`
          }}
        />
        <Navbar.Collapse id="collapse-links" className="justify-content-end">
          <MenuLink toPath="/" name="Home" pathname={location.pathname} />
          <MenuLink toPath="/blog" name="Blog" pathname={location.pathname} />
          <MenuLink
            toPath="/portfolio"
            name="Portfolio"
            pathname={location.pathname}
          />
          <MenuLink toPath="/about" name="About" pathname={location.pathname} />
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

Header.propTypes = {
  siteTitle: PropTypes.string
};

Header.defaultProps = {
  siteTitle: ``
};

export default Header;
